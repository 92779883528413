// extracted by mini-css-extract-plugin
export var ammoCaseStudyVideo = "bL_mj";
export var caseContactFormSection = "bL_kz";
export var caseSolutionRing = "bL_mk";
export var caseStudyBackground__bgColor = "bL_kp";
export var caseStudyBackground__lineColor = "bL_kq";
export var caseStudyCta__bgColor = "bL_kG";
export var caseStudyHead = "bL_kC";
export var caseStudyHead__imageWrapper = "bL_kn";
export var caseStudyProjectsSection = "bL_kB";
export var caseStudyQuote__dark = "bL_mf";
export var caseStudyQuote__light = "bL_mg";
export var caseStudySolution__ringOne = "bL_mh";
export var caseStudySolution__ringTwo = "bL_kv";
export var caseStudyTechSection = "bL_kH";
export var caseStudyVideo__ring = "bL_kF";
export var caseStudy__bgDark = "bL_km";
export var caseStudy__bgLight = "bL_kl";